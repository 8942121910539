import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { faUser, faEnvelope, faMapMarker, faCamera, faTrophy, faPaperPlane, faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import './AboutSinglePage.scss'
import { Link } from 'react-scroll'
import { Animated } from "react-animated-css";
import { useScrollingOn } from '../../Hooks/useScrollingOn'
import { useEffect } from 'react'

const AboutSinglePage = () => {
    const customSpy = useScrollingOn('about');

    return (
        <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" isVisible={customSpy}>
            <div className="row pt-5 pb-5 about-single-page" >
                <div className="col-lg-3 col-sm-12 ps-5 d-flex align-items-start justify-content-around flex-column" >
                    <img className="img-fluid" src="/assets/img/portfolio/64.jpg" alt="photographer based in Zurich Switzerland and Milan Italy known as @detojan" />
                    <div className="pt-1">
                        <a href='https://www.instagram.com/detojan/' style={{ textDecoration: 'underline', cursor: 'pointer', color:"#0b0f18" }}>
                            <FontAwesomeIcon icon={faInstagram} size={"lg"} />
                            &nbsp; @detojan
                        </a>
                        <br />
                        <br />
                        <p>
                            <FontAwesomeIcon icon={faMapMarker} size={"lg"} />
                            &nbsp; Italy, Milan
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faCamera} size={"lg"} />
                            &nbsp; Sony Alpha Ambassador
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faCameraRetro} size={"lg"} />
                            &nbsp; GoPro family member
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faTrophy} size={"lg"} />
                            &nbsp; 1 of the 30 best new Instagram's Talent
                        </p>
                    </div>
                </div>
                <div className="col-lg-9 col-sm-9 p-4 d-flex align-self-center justify-content-between flex-column about-single-page-description">
                    <h1>I am Dejan Tosetti</h1>
                    <p>a photographer based in Zurich, Switzerland known as @detojan.
                        <br />
                        I travelled the world and worked with many tourism agencies, hotels and brands such as My Switzerland, Cavo Tagoo Mykonos, Visit Madeira, Visit Dubai, MVMT, Sony, Lamborghini and many more.
                        Today, as Sony Alpha Ambassador I bring my camera everywhere to capture the best moments and inspire the next generation.
                        I now master photography and want to keep chasing my dream of being a full time travel photographer. In 2020, I was voted as one of the 30 best new talents on Instagram at the first Winter Summit Influencer organised by My Switzerland.
                        Further, I was selected as one of the 40 creators of Beautiful Destinations, the most popular and important "Travels/Hotels" Instagram page.
                        I believe that focusing on quality over quantity is fundamental to achieving the best outcome. My growth has been exponential from the onset due to my inquisitive nature to learn and explore new methods of working.
                        My work has led me to unimaginable opportunities and I have been fortunate enough to collaborate with a diverse range of clients from across the globe, many of which include the hospitality and tourism sector.
                        Having worked within the industry for over 5 years, I’ve established a great deal of credibility in an extremely competitive market, which wouldn’t have been possible if it wasn’t for the amazing feedback my clients leave me.
                        My secret to a great collaboration is to never settle and strive to be better with every move I make, and it’s this winning attitude that proves I got what it takes to elevate your brand to the next level.</p>
                    {/* <button className="about-infos-button">Click-Me</button> */}

                    <br />
                    <br />
                    <h1>TAKE YOUR BRAND TO THE NEXT LEVEL</h1>
                    <p>I offer something unique to the brands I work with and create value by authentic storytelling & content creation. My aim for every project is new, innovative and authentic content, in the language of your target audience and suitable for the respective social media platform. In line with the marketing and project goals I develop creative and innovative ideas and innovative strategies for your successful social media presence, either ads a one-off campaign or for long-term projects.
                        Visibility, quality and a good communication is what the brands get when working with me.</p>
                    {/* <button className="about-infos-button">Click-Me</button> */}
                    <br />
                    <br />
                    <div className="row mb-5">
                        <button className='button-black p-3'><Link activeClass="" to="contact" spy={true} smooth={true} offset={-50} duration={300}>We can create your dream togheter</Link></button>
                    </div>
                </div>
            </div>
        </Animated >
    )
}

export default AboutSinglePage;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTree, faCamera, faVideo, faGlobe, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import './ServicesSinglePage.scss'
import { Link } from 'react-scroll'
import { Animated } from 'react-animated-css'
import { useScrollingOn } from '../../Hooks/useScrollingOn'
import { useEffect } from 'react'

interface IPUProps {
    title: string,
    desc: string,
    background: string,
    backgroundType: "VIDEO" | "IMG" | "",
    close: () => void,
    isOpen: boolean
}
const ServicePopUP = ({ title, desc, background, backgroundType, close, isOpen }: IPUProps) => {
    useEffect(() => {
            setTimeout(() => {
                let homeVideo: HTMLVideoElement = document.getElementById("popup-video") as HTMLVideoElement;
                if (homeVideo)
                homeVideo.play()
            }, 500);
    }, [])
    return (
        <div onMouseLeave={close}>
            <div className='service-modal'>
                {backgroundType === "VIDEO" &&
                    <video id="popup-video" loop muted autoPlay preload="auto">
                        <source src={background} type="video/mp4" />
                    </video>
                }
                {backgroundType === "IMG" &&
                    <img src={background} alt="" />
                }
            </div>
            <h1>{title}</h1>
            <p>{desc}</p>
            <div className='service-modal-contact'>
                <Link activeClass="" to="contact" spy={true} smooth={true} offset={-50} duration={300}><button className="button-black">contact</button></Link>
            </div>
            <div className='service-modal-close' onClick={close}>
                <FontAwesomeIcon icon={faTimes} size='lg' />
            </div>
        </div>
    )
}

const ServicesSinglePage = () => {

    const customSpy = useScrollingOn('services');

    const [serviceTypeTitle, setServiceTypeTitle] = useState('');
    const [serviceTypeDesc, setServiceTypeDesc] = useState('');
    const [serviceTypeBackGround, setServiceTypeBackground] = useState('');
    const [serviceTypeBackGroundType, setServiceTypeBackgroundType] = useState<"VIDEO" | "IMG" | "">("");

    const [isPopUpOpen, setIsPopUpOpen] = useState(false);

    const handleLascapeService = () => {
        setServiceTypeTitle('Photography')
        setServiceTypeDesc('Often people think that all you need to take a photo is a phone, a nice subject/landscape and a few filters applied to get beautiful results. After 7 years of experience, studies and courses, important photographic projects and incredible collaborations I experienced that photography is a world that can surprise us every day.')
        setServiceTypeBackground('/assets/img/portfolio/63.jpg')
        setServiceTypeBackgroundType('IMG')
        setIsPopUpOpen(true)
    }

    const handleSocialVideos = () => {
        setServiceTypeTitle('video production')
        setServiceTypeDesc('A movie or simple video is not just what you see. A movie or a simple video is what you see, feel and most importantly what it conveys to you. Creating a story, studying shots, location scouting, having the right equipment, shooting, editing and exporting. This is the process behind every single project of mine.')
        setServiceTypeBackground('/assets/vid/social-vid.mp4')
        setServiceTypeBackgroundType('VIDEO')
        setIsPopUpOpen(true)
    }

    const handleCommercialPhotographyService = () => {
        setServiceTypeTitle('DRONE')
        setServiceTypeDesc('Sometimes we consider the drone a simple toy for fun in the sky. But knowing how to pilot the drone to get cinematic footage is something that requires training and especially the right technique. An FPV drone, is even more advanced to fly but the results are even more satisfying.')
        setServiceTypeBackground('/assets/vid/fpv.mp4')
        setServiceTypeBackgroundType('VIDEO')
        setIsPopUpOpen(true)
    }

    const Reset = () => {
        setIsPopUpOpen(false)
        setServiceTypeTitle('')
        setServiceTypeDesc('')
        setServiceTypeBackground('')
        setServiceTypeBackgroundType('')
    }

    return (
        <>
            <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" isVisible={customSpy}>
                <div className="row pt-5 pb-5 services-single-page">
                    <div className="col-lg-3 text-center mb-5" onClick={handleLascapeService} onMouseEnter={handleLascapeService}>
                        <img src='assets/img/icons/foto.png' />
                        <h3 className="pt-1">PHOTOGRAPHY</h3>
                    </div>
                    <div className="col-lg-3 text-center mb-5" onClick={handleCommercialPhotographyService} onMouseEnter={handleCommercialPhotographyService}>
                        <img src='assets/img/icons/drone.png' />
                        <h3 className="pt-1">DRONE</h3>
                    </div>
                    <div className="col-lg-3 text-center mb-5" onClick={handleSocialVideos} onMouseEnter={handleSocialVideos}>
                        <img src='assets/img/icons/video.png' />
                        <h3 className="pt-1">VIDEO PRODUCTION</h3>
                    </div>
                    <div className="col-lg-3 text-center mb-5">
                        <img src='assets/img/icons/website.png' />
                        <h3 className="pt-1">LANDING PAGES</h3>
                    </div>

                    <div className="row ps-2 pe-2">
                        <button className='button-black'><Link activeClass="" to="contact" spy={true} smooth={true} offset={-50} duration={300}>We can create your dream togheter</Link></button>
                    </div>
                </div>
            </Animated >
            <Animated className='service-modal-wrapper' animationIn="bounceIn" animationOut="bounceOut" isVisible={isPopUpOpen}>
                <ServicePopUP title={serviceTypeTitle} desc={serviceTypeDesc} background={serviceTypeBackGround} backgroundType={serviceTypeBackGroundType} close={Reset} isOpen={isPopUpOpen} />
            </Animated >

        </>
    )
}

export default ServicesSinglePage;
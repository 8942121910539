
import React from "react";
import { useState } from "react";
import Loader from "react-loader-spinner";
import Switch from "react-switch";

interface IProps {
  showCheck: boolean
}
const LoaderCustom = ({ showCheck }: IProps) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (checked: any) => {
    setChecked(true);
  }

  return (
    <div className="loader-custom">
      {/* <Loader
        type="Puff"
        color="#fff"
        height={100}
        width={100}
      /> */}
      <img src="./assets/img/detojan-logo.png" alt="" />
      {/* <div className="loader-custom-num"></div> */}
      {showCheck &&
        <div className="loader-custom-switch">
          <Switch
            checked={checked}
            onChange={handleChange}
            onColor="#0b0f18"
            onHandleColor="#0b0f18"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(11, 15, 24, 0.2)"
            height={20}
            width={350}
            className="react-switch"
            id="material-switch"
          />
          <p>Beautiful things require a DRAG</p>
        </div>
      }
    </div >


  )
}

export default LoaderCustom;
import React, { useEffect, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './NFT.scss'
import LoaderCustom from '../Loader/Loader';
import { useScrollingOn } from '../../Hooks/useScrollingOn';
import { Animated } from 'react-animated-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface IProps {
    setNft: () => void
}
export const NFT = ({ setNft }: IProps) => {
    const form = useRef();
    const [senderName, setSenderName] = useState('');

    const [formCompleted, setFormCompleted] = useState(true);
    const [thxVisible, setThxVisible] = useState(false);

    const [spinnerLoading, setSpinnerLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setSpinnerLoading(false)
        }, 300);
    }, [thxVisible])

    const sendEmail = (e: any) => {
        e.preventDefault();

        const name: any = document.getElementById("name-nft");
        const mail: any = document.getElementById("email-nft");

        let nameValue = ''
        let mailValue = ''
        if (name) {
            nameValue = ' ' + name.value;
        }
        if (mail) mailValue = mail.value;

        if (nameValue && mailValue) {
            console.log("nel giusto")
            setSpinnerLoading(true)
            //@ts-ignore
            emailjs.sendForm(`${process.env.REACT_APP_MAIL_SERVICE}`, `${process.env.REACT_APP_MAIL_NFT_TEMPLATE}`, form.current, `${process.env.REACT_APP_MAIL_USER}`)
                .then((result) => {
                    console.log(form.current);
                    setSenderName(nameValue)
                    setThxVisible(true)
                }, (error) => {
                    console.log(error.text);
                });
        }
        else {
            console.log("errore")
            setFormCompleted(false)
        }
    };

    return (
        <div className="row nft d-flex justify-content-center align-items-center" style={{ background: '#0B0F18' }}>
            {
                spinnerLoading &&
                <LoaderCustom showCheck={false}/>
            }
            {!thxVisible
                ?
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '1rem', right: '2rem' }}>
                        <FontAwesomeIcon icon={faTimes} size='2x' onClick={setNft} />
                    </div>
                    <div className="col-lg-12  p-2 pb-0 nft-description  d-flex flex-column justify-content-center align-items-center">
                        <h1>My NFT COming Soon</h1>
                        <p>Sign up if you don’t want to miss the NFT drop by @detojan_nft.</p>
                    </div>
                    <div className="col-lg-12  d-flex flex-column justify-content-center align-items-center">
                        {/* @ts-ignore */}
                        <form ref={form} className="form-minimal w-75" onSubmit={sendEmail}>

                            <>
                                <label>Name</label>
                                <input id="name-nft" type="text" name="user_name" onChange={() => setFormCompleted(true)} />
                                <label>Email</label>
                                <input id="email-nft" type="email" name="user_email" onChange={() => setFormCompleted(true)} />
                                <input className="button-black" type="submit" value="Send" />
                                {!formCompleted && <p style={{ marginTop: '1rem' }}>Fill all Mandatory fields, Please.</p>}
                            </>

                        </form>
                    </div>
                </div>
                :
                <div style={{position:'relative'}}>
                    <div style={{ position: 'absolute', bottom: '0rem', right: '2rem' }}>
                        <FontAwesomeIcon icon={faTimes} size='2x' onClick={setNft} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>Thanks{senderName} for your message, <br /> I will answer soon.</h1>
                    </div>
                </div>

            }
        </div>
    );
};

import { useEffect, useLayoutEffect } from 'react';
import './CarouselSlider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const CarouselSlider = () => {

    return (
        <div className="slider">
            <div className="slide-track">
                <div className="slide">
                    <img src="./assets/img/logos/apple.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/artlist.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/BD.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/burton.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Gopro.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Huawei.png" />
                </div>
                <div className="slide ">
                    <img src="./assets/img/logos/Lambo.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/madeira.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/preferred.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/sony.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Swatch.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Switzerland.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/vw.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Gopro.png" />
                </div>
                {/* second cycle */}
                
                <div className="slide">
                    <img src="./assets/img/logos/apple.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/artlist.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/BD.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/burton.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Gopro.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Huawei.png" />
                </div>
                <div className="slide ">
                    <img src="./assets/img/logos/Lambo.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/madeira.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/preferred.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/sony.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Swatch.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Switzerland.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/vw.png" />
                </div>
                <div className="slide">
                    <img src="./assets/img/logos/Gopro.png" />
                </div>
            </div>
        </div>
    )
}

export default CarouselSlider;
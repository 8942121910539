import './FooterMinimal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'


const FooterMinimal = () => {
    return (
        <footer className="footer-minimal container m-0">
            <div className="footer-minimal-dejan">
                <p> <img src="./assets/img/detojan-logo.png" alt="" /> 2022 Dejan Tossetti </p>
                <div className='footer-minimal-dejan-socials'>
                    <a href="https://www.instagram.com/detojan/"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                    <a href='https://api.whatsapp.com/send?phone=+393382627857&text=Hi%20Dejan,%20I%20would%20like%20to%20work%20together. Would%20you%20be%20willing%20to%20have%20a%20chat?'><FontAwesomeIcon icon={faWhatsapp} size="2x" /></a>
                </div>
            </div>
            {/* <p className="footer-minimal-william"> Developed with ❤️ by William Bardon </p> */}
        </footer>
    )
}

export default FooterMinimal;
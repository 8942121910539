import { useEffect, useState } from "react"

export const useScrollingOn = (sectionName: string) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                entry.target.querySelector(`#${sectionName}`);
                if (entry.isIntersecting)
                    setIsVisible(true)
                else setIsVisible(false)
            });
        });

        const sectionToObserve = document.querySelector(`#${sectionName}`)
        if (sectionToObserve)
            observer.observe(sectionToObserve);
    }, [])
    return isVisible;
}


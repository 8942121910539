import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faUser, faEnvelope, faMapMarker, faCamera, faTrophy, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import './PortfolioSinglePage.scss'
import { CSSProperties, useEffect, useState } from 'react'
import { transform } from 'typescript'
import LoaderCustom from '../../Components/Loader/Loader'
import CardsCarousel from '../../Components/CardsCarousel/CardsCarousel'
import Card from '../../Components/CardsCarousel/Card'
import { Modal, ModalBody } from 'react-bootstrap'
import { useScrollingOn } from '../../Hooks/useScrollingOn'
import { Animated } from 'react-animated-css'
import { useWindowSize } from '../../Hooks/useWindowSize'
const PortfolioSinglePage = () => {
    const [selected, setSelected] = useState('Automotive');
    const [isGroupVisible, setIsGroupVisible] = useState(!useScrollingOn('portfolio'));
    // const [containerStyle, setContainerStyle] = useState<CSSProperties>({transform:'translateX(0)', transition: '2s .2s'})

    const handleSelected = (selected: string) => {
        // setContainerStyle({transform:'translateX(1500px)', transition: '2s .2s'})

        // setTimeout(() => {
        setIsGroupVisible(false);
        setTimeout(() => {
            setIsGroupVisible(true);
            setSelected(selected);
        }, 300);
        //     setContainerStyle({transform:'translateX(0)', transition: '2s .2s'})
        // }, 2100);
    }

    interface IGroupSelected {
        groupSelected: string
    }

    const GroupSelected = ({ groupSelected }: IGroupSelected) => {
        let photosAutomotive = ['assets/img/portfolio/5.jpg', 'assets/img/portfolio/2.jpg', 'assets/img/portfolio/1.jpg', 'assets/img/portfolio/0.jpg', 'assets/img/portfolio/3.jpg', 'assets/img/portfolio/4.jpg']
        let altAutomotive = ['volkswagen photo edit', 'lamborghini selfie', 'mercedes photo edit photoshop', 'volkswagen car', 'lamborghini photo edited photographer', 'merceds car editing']
        if (groupSelected === 'Automotive') {
            return <Group photos={photosAutomotive} alt={altAutomotive} isGroupVisible={isGroupVisible} />
        }

        let photosLandscape = ['assets/img/portfolio/32.jpg', 'assets/img/portfolio/26.jpg', 'assets/img/portfolio/48.jpg', 'assets/img/portfolio/28.jpg', 'assets/img/portfolio/11.jpg', 'assets/img/portfolio/35.jpg']
        let altLandscape = ['engadin lake photography', 'loroten hattvika lodge sunset photo', 'vernazza cinque terre beatiful italy photography', 'loroten hattvika lodge swiss photographer', 'montagna seceda ortisei foto fotografo montagna', 'engadin switzerland cinematic']
        if (groupSelected === 'Landscape') {
            return <Group photos={photosLandscape} alt={altLandscape} isGroupVisible={isGroupVisible} />
        }

        let photosArchitecture = ['assets/img/portfolio/6.jpg', 'assets/img/portfolio/10.jpg', 'assets/img/portfolio/43.jpg', 'assets/img/portfolio/14.jpg', 'assets/img/portfolio/27.jpg', 'assets/img/portfolio/19.jpg']
        let altArchitecture = ['milan piazza gae aulenti milano street photography', 'zurich bibliotek biblioteca zurigo study photo', 'dubai vida hotel lifestyle photography', 'prontesina hotel kronenhof', 'loroten hattvika lodge architecture photography', 'prontesina hotel kronenhof photo']
        if (groupSelected === 'Architecture') {
            return <Group photos={photosArchitecture} alt={altArchitecture} isGroupVisible={isGroupVisible} />
        }
        return <div></div>
    }

    interface IGroup {
        photos: string[],
        alt: string[],
        isGroupVisible: boolean
    }
    const Group = ({ photos, alt, isGroupVisible }: IGroup) => {
        const [spinnerLoading, setSpinnerLoading] = useState(true);
        // const customSpy = useScrollingOn('portfolio');

        useEffect(() => {
            setTimeout(() => {
                setSpinnerLoading(false)
            }, 300);
        }, [])

        return (
            <Animated animationIn="fadeInRight" animationOut="fadeOutLeft" isVisible={isGroupVisible}>
                <div className='container-portfolio'>
                    {/* {
                        spinnerLoading &&
                        <LoaderCustom />
                    } */}
                    <div className="row mt-5 text-center">
                        <div className="col-lg-4 p-3">
                            <img className='img-fluid' src={photos[0]} alt={alt[0]} />
                        </div>
                        <div className="col-lg-4 p-3">
                            <img className='img-fluid' src={photos[1]} alt={alt[1]} />
                        </div>
                        <div className="col-lg-4 p-3">
                            <img className='img-fluid' src={photos[2]} alt={alt[2]} />
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-lg-4 p-3">
                            <img className='img-fluid' src={photos[3]} alt={alt[3]} />
                        </div>
                        <div className="col-lg-4 p-3">
                            <img className='img-fluid' src={photos[4]} alt={alt[4]} />
                        </div>
                        <div className="col-lg-4 p-3">
                            <img className='img-fluid' src={photos[5]} alt={alt[5]} />
                        </div>
                    </div>
                </div>
            </Animated >
        )
    }

    const windowSize = useWindowSize();

    return (
        <div className="row pt-5 pb-5 ps-3 pe-3">
            <div className="horizontal-nav-menu mb-3">
                <p className="hover-underline-animation-black " onClick={() => handleSelected('Automotive')}>Automotive</p>
                <p className="hover-underline-animation-black " onClick={() => handleSelected('Landscape')}>Landscape</p>
                <p className="hover-underline-animation-black " onClick={() => handleSelected('Architecture')}>Architecture and Design</p>
            </div>
            <GroupSelected groupSelected={selected} />
            {windowSize.width < 700 &&
                <div className="horizontal-nav-menu">
                    <p className="hover-underline-animation-black " onClick={() => handleSelected('Automotive')}>Automotive</p>
                    <p className="hover-underline-animation-black " onClick={() => handleSelected('Landscape')}>Landscape</p>
                    <p className="hover-underline-animation-black " onClick={() => handleSelected('Architecture')}>Architecture and Design</p>
                </div>
            }
        </div>
    )
}

export default PortfolioSinglePage;
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Container/Home/Home';
import About from './Container/About/About';
import { ContactUs } from './Components/ContactUs/ContactUs';
import CardsCarouselContainer from './Components/CardsCarousel/CardCarouselContainer';
import MasonryComponent from './Components/Masonry/Masonry';
import CarouselSlider from './Components/CarouselSlider/CarouselSlider';
import Services from './Container/Services/Services';


function App() {
  return (
    <BrowserRouter>
      <div className="App">

        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/portfolio" element={<CardsCarouselContainer />} /> */}
          {/* <Route path="/portfolio2" element={<MasonryComponent />} /> */}
          {/* <Route path="/services" element={<Services />} /> */}
          {/* <Route path="/nft" element={<Services />} /> */}
        </Routes>

      </div>
    </BrowserRouter>
  );
}

export default App;

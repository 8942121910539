import './HorizontalNav.scss'
import { Link } from "react-scroll";
import { useWindowSize } from '../../Hooks/useWindowSize';
import HamNav from '../HamNav/HamNav';
import { Animated } from 'react-animated-css';
import { NFT } from '../NFT/NFT';
import { useState } from 'react';

interface IProps {
    setNft: ()=>void
}
const HorizontalNav = ({setNft}: IProps) => {
    const windowSize = useWindowSize();

    return (
        <div className="horizontal-nav">
            <div className="horizontal-nav-logo">
                <Link to="home" spy={true} smooth={true} offset={0} duration={300}><img style={{ cursor: 'pointer'}} src="/assets/img/detojan-full-logo-white.png" alt="" /></Link>
            </div>
            <div className="horizontal-nav-menu" style={windowSize.width < 890 ? { display: 'none' } : {}}>
                {/* <Link activeClass="active" to="home" spy={true} smooth={true} offset={10} duration={300}><p className="hover-underline-animation">Home</p></Link> */}
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">About</p></Link>
                <Link activeClass="active" to="portfolio" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">Portfolio</p></Link>
                <Link activeClass="active" to="services" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">Services</p></Link>
                <Link onClick={setNft} to=""><p className="hover-underline-animation">NFT</p></Link>
            </div>
            <div className="horizontal-nav-contact" style={windowSize.width < 890 ? { display: 'none' } : {}}>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-50} duration={300}><button className="button-black">contact</button></Link>
            </div>

            {
                windowSize.width <= 890 &&
                <HamNav setNft={setNft} />
            }
        </div>
    )
}

export default HorizontalNav;
import Card from "../CardsCarousel/Card";
import Masonry from "react-masonry-css";
import './MasonryMinimal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'


//@ts-ignore
import Tilt from 'react-tilt';
import { useWindowSize } from "../../Hooks/useWindowSize";
import { useEffect, useState } from "react";
import { ContactUs } from "../ContactUs/ContactUs";
import VerticalNav from "../VerticalNav/VerticalNav";

const CARDS = 31;

const MasonryMinimal = () => {
    const windowSize = useWindowSize();
    const [col, setCol] = useState(3);

    const [transNav, setTransNav] = useState(0);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleTransNavPhoto = () => {
        if (transNav === 0) setTransNav(-200)
        else setTransNav(0)
    }

    useEffect(() => {
        console.log(isFormVisible)
    }, [isFormVisible])


    useEffect(() => {
        if (windowSize.width > 1800) setCol(4)
        if (windowSize.width < 1800) setCol(3)
        if (windowSize.width < 1100) setCol(2)
        if (windowSize.width < 800) setCol(1)
    }, [windowSize])

    return (
        <>
            <div className='masonry-minimal-container'>
                <Masonry
                    breakpointCols={col}
                    className="masonry-minimal-grid"
                    columnClassName="masonry-minimal-grid_column">
                    {/* array of JSX items */}
                    {[...new Array(CARDS)].map((_, i) => (
                        // <Tilt className="Tilt" options={{ max: 25 }} style={{}} >
                        //     <Card
                        //         // title={'Little photo description ' + (i + 1)} 
                        //         content={`./assets/img/portfolio/${i}resized.jpg`} />
                        // </Tilt>
                        <>
                            <div>
                                <img
                                    // style={{marginTop: Math.floor(Math.random() * 40) + 'rem'}}
                                    src={`./assets/img/portfolio/${i}.jpg`}
                                />
                                {/* <img
                    style={{marginTop: Math.floor(Math.random() * 40) + 'rem'}} 
                     src={`./assets/img/portfolio/${i}resized.jpg`} /> */}
                            </div>
                            <div>
                                <img
                                    // style={{marginTop: Math.floor(Math.random() * 40) + 'rem'}}
                                    src={`./assets/img/portfolio/${i+10}.jpg`}
                                />
                                {/* <img
                    style={{marginTop: Math.floor(Math.random() * 40) + 'rem'}} 
                     src={`./assets/img/portfolio/${i}resized.jpg`} /> */}
                            </div>
                        </>
                        
                    ))}
                </Masonry>
            </div>
        </>

    )
}

export default MasonryMinimal;
import React, { useEffect, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactUsMinimal.scss'
import LoaderCustom from '../Loader/Loader';
import { useScrollingOn } from '../../Hooks/useScrollingOn';
import { Animated } from 'react-animated-css';

export const ContactUsMinimal = () => {
  const form = useRef();
  const [senderName, setSenderName] = useState('');

  const [formCompleted, setFormCompleted] = useState(true);
  const [thxVisible, setThxVisible] = useState(false);

  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const customSpy = useScrollingOn('contact');

  useEffect(() => {
    setTimeout(() => {
      setSpinnerLoading(false)
    }, 300);
  }, [thxVisible])

  const sendEmail = (e: any) => {
    e.preventDefault();

    const name: any = document.getElementById("name");
    const mail: any = document.getElementById("email");

    let nameValue = ''
    let mailValue = ''
    if (name) {
      nameValue = ' ' + name.value;
    }
    if (mail) mailValue = mail.value;

    if (nameValue && mailValue) {
      console.log("nel giusto")
      setSpinnerLoading(true)
      //@ts-ignore
      emailjs.sendForm(`${process.env.REACT_APP_MAIL_SERVICE}`, `${process.env.REACT_APP_MAIL_TEMPLATE}`, form.current, `${process.env.REACT_APP_MAIL_USER}`)
        .then((result) => {
          console.log(form.current);
          setSenderName(nameValue)
          setThxVisible(true)
        }, (error) => {
          console.log(error.text);
        });
    }
    else {
      console.log("errore")
      setFormCompleted(false)
    }
  };

  return (
    <Animated animationIn="fadeInRight" animationOut="fadeOutLeft" isVisible={customSpy}>
    <div className="row contact-us">
      {
        spinnerLoading &&
        <LoaderCustom showCheck={false}/>
      }
      {!thxVisible
        ?
        <>
          <div className="col-lg-6 contact-us-description">
            <h1>Ready to Create Magic?</h1>
            <p>If you are an adventure-minded brand who aligns with my core values of authenticity &  highest quality standards, I would love to connect with you and bring your brand to the next level! No matter if photography, videos or content creation of any kind.</p>
          </div>
          <div className="col-lg-6">
            {/* @ts-ignore */}
            <form ref={form} className="form-minimal" onSubmit={sendEmail}>

              <>
                <label>Name</label>
                <input id="name" type="text" name="user_name" onChange={() => setFormCompleted(true)} />
                <label>Email</label>
                <input id="email" type="email" name="user_email" onChange={() => setFormCompleted(true)} />
                <label>Message</label>
                <textarea name="message" placeholder="I contact you because I would like to collaborate" />
                <input className="button-black" type="submit" value="Send" />
                {!formCompleted && <p style={{ marginTop: '1rem' }}>Fill all Mandatory fields, Please.</p>}
              </>

            </form>
          </div>
        </>
        :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h1>Thanks{senderName} for your message, <br /> I will answer soon.</h1>
        </div>
      }
    </div>
    </Animated >
  );
};
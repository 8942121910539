import './HamNav.scss'
import { Link } from "react-scroll";
import { useState } from 'react';

interface IProps {
    setNft: ()=>void
}
const HamNav = ({setNft}: IProps) => {
    const [hamClass, setHamClass] = useState('')

    const handleNavBar = () => {
        if (!hamClass) setHamClass('open')
        else setHamClass('')
    }

    const handleNft =()=>{
        handleNavBar()
        setNft()
    }
    return (
        <div className="ham-nav">
            <div className={`ham-nav-hamburger ${hamClass}`} onClick={handleNavBar}>
                <span className={`ham-nav-hamburger__button`} ></span>
            </div>
            <div className={`ham-nav-content ${hamClass}`}>
                {/* <div className="ham-nav-menu"> */}
                    <Link onClick={handleNavBar} activeClass="active" to="home" spy={true} smooth={true} offset={10} duration={300}><p className="hover-underline-animation">Home</p></Link>
                    <Link onClick={handleNavBar} activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">About</p></Link>
                    <Link onClick={handleNavBar} activeClass="active" to="portfolio" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">Portfolio</p></Link>
                    <Link onClick={handleNavBar} activeClass="active" to="services" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">Services</p></Link>
                    <Link onClick={handleNft} to=""><p className="hover-underline-animation">NFT</p></Link>
                {/* </div>
                <div className="ham-nav-contact"> */}
                    <Link activeClass="" to="contact" spy={true} smooth={true} offset={-50} duration={300}><p className="hover-underline-animation">contact</p></Link>
                {/* </div> */}
            </div>
        </div>
    )
}

export default HamNav;